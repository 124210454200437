import { isSessionStorageSupported } from "../storage/is-sessionstorage-supported"
import { v4 } from "uuid"

/** Returns either a newly set or an already saved id from `sessionStorage`. */
export function getSessionId() {
  if (!isSessionStorageSupported()) return v4()
  const id = sessionStorage.getItem("X-Session-ID")
  if (id) return id

  const newId = v4()
  sessionStorage.setItem("X-Session-ID", newId)

  return newId
}

export class HttpError extends Error {
  response: Response
  constructor(message: string, response: Response) {
    super(message)
    this.name = "HttpError"
    this.message = message
    this.response = response
  }
}

interface IHTTPOptions {
  token?: string | null | undefined
  method?: string
  body?: any
  customHeaders?: { [key: string]: string } | undefined
}

const defaultOptions: IHTTPOptions = {
  method: "GET",
}

export async function http(url: string, options?: IHTTPOptions) {
  const { token, method, body, customHeaders } = {
    ...defaultOptions,
    ...options,
  }
  const sessionId = typeof window !== "undefined" ? getSessionId() : null
  const correlationId = v4()
  const init = {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "X-Correlation-ID": correlationId,
      ...(sessionId ? { "X-Session-ID": sessionId } : undefined),
      ...(token ? { Authorization: `Bearer ${token}` } : undefined),
      ...customHeaders,
    },
    method,
    body,
  }

  const response = await fetch(url, init)

  if (response.ok) {
    if (response.status === 204) {
      return response
    }
    return await response.json()
  } else {
    throw new HttpError(`Network response was not ok, url: ${url}`, response)
  }
}

export async function get<T>(
  url: string,
  token?: string | null | undefined,
  customHeaders?: { [key: string]: string } | undefined
): Promise<T> {
  return http(url, { token, customHeaders })
}

export async function post<T>(
  url: string,
  body: string,
  token?: string | null | undefined,
  customHeaders?: { [key: string]: string } | undefined
): Promise<T> {
  return http(url, { token, method: "POST", body, customHeaders })
}

export async function put<T>(
  url: string,
  body: string,
  token?: string | null | undefined,
  customHeaders?: { [key: string]: string } | undefined
): Promise<T> {
  return http(url, { token, method: "PUT", body, customHeaders })
}

import { post } from "@nhi/utils"
import { UserCategoryType } from "data/internal/interfaces/userCategory"

/**
 * Sets the user-category cookie to the value provided in the request body ("layman" or "professional")
 * @param type User type
 * @returns
 */
export async function setUserCategory(type: UserCategoryType) {
    const body = JSON.stringify({ value: type })
    return await post("/api/userCategory", body)
}

import { red } from "@mui/material/colors"

import { generateDefaultTheme, getMixins } from "./generateDefaultTheme"

/**
 * Theme configurations for palette used for both useCategory = "public" and userCategory = "professional"
 */

const basePalette = {
    primary: {
        light: "#305169",
        main: "#1F394C",
        dark: "#152C3E",
        50: "#F8FCFF",
        100: "#E2F3FF",
        200: "#CDEAFF",
        300: "#B5DDFB",
        400: "#94BFDE",
        500: "#77A1C1",
        600: "#5C85A3",
        700: "#456B86",
        800: "#305169",
        900: "#1F394C",
        link: "#0A7FAE"
    },

    grey: {
        50: "#FAFAFA",
        100: "#F4F4F5",
        200: "#E4E4E7",
        300: "#D4D4D8",
        400: "#A1A1AA",
        500: "#71717A"
    },
    advertiser: {
        light: "#8730CA",
        main: "#6B1DA8",
        dark: "#510E86",
        50: "#F7EDFF",
        100: "#E6C5FF",
        200: "#D49DFF",
        300: "#C376FF",
        400: "#A448EC",
        500: "#8730CA",
        600: "#6B1DA8",
        700: "#510E86",
        800: "#3A0464",
        900: "#250042"
    },
    pregnancy: {
        light: "#FFEEF1",
        main: "#F9A4B3",
        dark: "#EC5C76"
    },
    error: {
        main: red.A400
    },

    background: {
        default: "#F4F4F5"
    },

    text: { primary: "#333333" }
}

/**
 * Theme configurations used when userCategory = "public" or no userCategory is set
 */

const publicPalette = {
    ...basePalette,
    secondary: {
        light: "#14CFC2",
        main: "#007D75",
        dark: "#00544F",
        50: "#EAFFFD",
        100: "#C5FFFB",
        200: "#A0FFF8",
        300: "#7BFFF6",
        400: "#56FFF4",
        500: "#30F8EA",
        600: "#14CFC2",
        700: "#00A69B",
        800: "#007D75",
        900: "#00544F",
        contrastText: "#000000",
        opacityBackground: "hsla(176, 93%, 58%,0.3)",
        link: "#005AC5"
    }
}

const defaultTheme = generateDefaultTheme(
    { ...publicPalette },
    getMixins({ ...publicPalette })
)
export default defaultTheme

/**
 * Theme configurations used when userCategory = "professional"
 * Extends the default publicTheme
 */

const cdsPalette = {
    ...basePalette,
    secondary: {
        light: "#3CC7FF",
        main: "#0A7FAE",
        dark: "#005F85",
        50: "#EBF9FF",
        100: "#C8EFFF",
        200: "#A5E5FF",
        300: "#82DBFF",
        400: "#5FD1FF",
        500: "#3CC7FF",
        600: "#20A2D7",
        700: "#0A7FAE",
        800: "#005F85",
        900: "#00425C",
        contrastText: "#000000",
        opacityBackground: "hsla(197, 100%, 62%, 0.3)",
        link: "#005AC5"
    }
}

export const cdsTheme = generateDefaultTheme(
    { ...cdsPalette },
    getMixins({ ...cdsPalette })
)
